var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "mew-overlay",
    {
      attrs: {
        footer: _vm.footer,
        "color-type": "white",
        "show-overlay": _vm.isOverlayOpen,
        title: "My paper wallet",
        "content-size": "xlarge",
        close: _vm.close,
      },
      on: { closeOverlay: _vm.close },
    },
    [
      _c(
        "div",
        {
          ref: "printContainer",
          staticClass: "printable-wallet printable-wallet-content",
        },
        [_vm.instance ? _c("paper-wallet-to-print") : _vm._e()],
        1
      ),
      _vm.instance
        ? _c("paper-wallet-to-display", {
            staticClass: "printable-wallet-display",
          })
        : _vm._e(),
      _c(
        "div",
        { staticClass: "d-flex justify-center mt-12" },
        [
          _c("mew-button", {
            staticClass: "printButton",
            attrs: { title: "Print", "btn-size": "xlarge" },
            nativeOn: {
              click: function ($event) {
                return _vm.print.apply(null, arguments)
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }